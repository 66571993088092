import { graphql, Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import ContainerBox from '../../components/container-box/container-box';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../components/svg-icon/svg-icon-enum';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import { IAboutUsProperties } from '../../global/wordpress-page/about-us/about-us-interface';
import { IACFProfile } from '../../global/wordpress-page/acf-interface';

export default class AboutUs extends BaseComponent<IAboutUsProperties> {
  protected declareTranslateCollection(): string {
    return 'aboutUs';
  }

  public render(): ReactNode {
    const { [0]: aboutUsPage } = this.props.data.aboutUsPage.nodes;
    // const { link, content, title, } = this.props.data.page.nodes[0] ?? {};

    return (
      <WordPressPageLayout title={aboutUsPage.title}>
        <ContainerBox className="c-section--pageheader">
          <div className="c-pageheader">
            <BreadCrumb
              crumbItems={[
                {
                  label: 'State of Democracy',
                  location: '/',
                },
                {
                  label: aboutUsPage.title,
                  location: '/about-us',
                },
              ]}
            />
            <div className="c-section">
              <h1 className="c-section__title">{aboutUsPage.title}</h1>
              <div
                className="s-text"
                dangerouslySetInnerHTML={{ __html: aboutUsPage.content }}
              ></div>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--about">
          <div className="c-about">
            {aboutUsPage.acfContent.profiles.map((profile: IACFProfile) => {
              return (
                <div className="c-about-items">
                  <div className="row c-about__wrap">
                    <div className="col-lg-7 c-about-col">
                      <div className="c-about__logo">
                        <img
                          src={profile.logo.sourceUrl}
                          alt={profile.logo.title}
                          width={profile.customLogoWidth}
                        />
                      </div>
                      <div
                        className="s-content"
                        dangerouslySetInnerHTML={{
                          __html: profile.description,
                        }}
                      ></div>
                      <div className="c-about__cta">
                        {profile.link.target === '_blank' ? (
                          <OutboundLink
                            href={profile.link.url}
                            target={profile.link.target}
                            rel="nofollow, noopener"
                            className="c-btn is-transparent c-btn--about"
                          >
                            {profile.link.title}
                            <SvgIcon
                              width="18"
                              viewBox="0 0 18 18"
                              paths={[
                                {
                                  command: SvgIconPathCommand.ABOUT_ICON,
                                  fill: '#4A1D96',
                                },
                              ]}
                            />
                          </OutboundLink>
                        ) : (
                          <OutboundLink
                            href={profile.link.url}
                            className="c-btn is-transparent c-btn--about"
                          >
                            {profile.link.title}
                            <SvgIcon
                              width="18"
                              viewBox="0 0 18 18"
                              paths={[
                                {
                                  command: SvgIconPathCommand.ABOUT_ICON,
                                  fill: '#4A1D96',
                                },
                              ]}
                            />
                          </OutboundLink>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 offset-lg-1 c-about-col">
                      <div className="c-about__headline">{profile.heading}</div>
                      <div className="c-about__profile">
                        <div className="c-about__cover">
                          <img
                            src={profile.avatar.sourceUrl}
                            alt={profile.avatar.title}
                          />
                        </div>
                        <div className="c-about__info">
                          <div className="c-about__text c-about-text--name">
                            {profile.userInfo.name}
                          </div>
                          <div className="c-about__text c-about-text--role">
                            {profile.userInfo.role}
                          </div>
                          <div className="c-about__text c-about-text--email">
                            <Link to={`mailto:${profile.userInfo.email}`}>
                              {profile.userInfo.email}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ContainerBox>
      </WordPressPageLayout>
    );
  }
}
export const query = graphql`
  query AboutUsPage {
    aboutUsPage: allWpPage(filter: { slug: { eq: "about-us" } }) {
      nodes {
        id
        acfContent: acfAboutUs {
          fieldGroupName
          profiles {
            ... on WpPage_Acfaboutus_Profiles_Profiles {
              description
              fieldGroupName
              heading
              customLogoWidth
              link {
                target
                title
                url
              }
              avatar {
                title
                uri
                sourceUrl
                sizes
              }
              logo {
                title
                uri
                sourceUrl
                sizes
              }
              userInfo {
                email
                name
                role
                fieldGroupName
              }
            }
          }
        }
        title
        uri
        slug
        content
        status
      }
    }
  }
`;
